export const inboundPermissions = {
  methods: {
    canPrint(item) {
      if (
        item?.inboundStatus?.name == "closed" &&
        this.$admin.can("inbound-print")
      )
        return true;
      return false;
    },

    canPrintOrder(item) {
      if (item.supply_order_id && this.$admin.can("supplyOrder-print"))
        return true;
      return false;
    },

    canReopen(item) {
      const received_at =
        new Date(item.received_at).getTime() / (1000 * 60 * 60);
      const now = new Date().getTime() / (1000 * 60 * 60);
      if (
        item.inboundStatus?.name == "closed" &&
        now - received_at < 48 &&
        this.$admin.can("inbound-restore")
      )
        return true;
      return false;
    },
  },
};
